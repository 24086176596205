<template>
  <transition name="fade" mode="in-out">
    <div class="guidetext" v-if="showGuideText">
      <div class="holder">
        <div>
          <p>Välj dina ingredienser genom att klicka på dem.</p>
          <p>Du kan välja flera och bläddra bland kategorierna.</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showGuideText: false
  }),
  mounted() {
    this.showGuideText = this.show
    document.addEventListener('click', () => {
      this.showGuideText = false
    })
  },
  watch: {
    show(show) {
      this.showGuideText = show
    }
  }
}
</script>

<style lang="scss" scoped>
.guidetext {
  background-color: $green-90;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;

  .holder {
    @include rem(min-height, 66px);
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }
}

p {
  @include rem(font-size, 14px);
  @include rem(line-height, 22px);
  font-weight: 900;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

@media #{$menu-wide} {
  .guidetext {
    .holder {
      @include rem(min-height, 100px)
    }
  }
}

</style>