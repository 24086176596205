<template>
  <button class="info-btn" @click="show">
    <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Pauluns---Mixa&amp;vinn---skisser" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="03---D---Pauluns---Mixa&amp;vinn---Välj&amp;mixa" transform="translate(-320.000000, -866.000000)">
          <g id="info/white" transform="translate(318.000000, 864.000000)">
            <g id="Group" transform="translate(2.000000, 2.000000)">
              <circle id="oval" cx="18" fill="#ffffff" cy="18" r="18"></circle>
              <text id="i" font-family="HelveticaNeue-Bold, Helvetica Neue" fill="#a9ad00" font-size="24" font-weight="bold">
                <tspan x="15" y="26">i</tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </button>
</template>

<script>
export default {
  methods: {
    show() {
      this.$store.dispatch('rule/showRules', { show: true })
    },
  }
}
</script>

<style lang="scss">
.info-btn {
  @include rem(width, 26px);
  @include rem(height, 26px);
  @include rem(left, $gutter);
  @include rem(top, $gutter);
  padding: 0;
  border: 0;
  background: transparent;
  display: flex;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 400;
  position: absolute;
  padding: 0;

  > svg {
    @include rem(width, 26px);
    @include rem(height, 26px);
  }
}

@media #{$menu-wide} {
  .info-btn {
    @include rem(width, 40px);
    @include rem(height, 40px);
    @include rem(left, $gutter);
    @include rem(bottom, $gutter);
    top: auto;

    > svg {
      @include rem(width, 40px);
      @include rem(height, 40px);
    }
  }
}
</style>