<template>
  <transition name="modal" mode="in-out">
    <div class="modal" v-show="show" ref="modal">
      <div class="overlay" @click="clickOutside"></div>
      <div class="content">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    clickOutside: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    show(show) {
      if (show) {
        disableBodyScroll(this.$refs.modal)
      } else {
        clearAllBodyScrollLocks()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  display: flex;
  background-color: $green-75;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow-y: scroll;
}

.overlay {
  position: absolute;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.content {
  @include rem(max-width, 350px);
  @include rem(border-radius, 2px);
  @include rem(padding, $gutter);
  background-color: $merino;
  width: 100%;
  margin: auto;
  transition: all .3s ease;
  box-shadow: 0 8px 8px 0 rgba(#000000, .15);
}

.modal-enter-active,
.modal-leave-active {
  transition: all .2s ease;

  .content {
    transition-delay: .2s;
    transform: translateY(0);
    opacity: 1;
  }
}

.modal-enter, .modal-leave-to {
  opacity: 0;

  .content {
    opacity: 0;
    transform: translateY(20px);
  }
}

</style>
