<template>
<button @click="clicked({ ingredient, isActive })" :class="{ '-active': isActive, '-disabled': isDisabled, '-garnish': showGarnish }" class="ingredient">
  <img v-if="ingredient" :class="{ '-garnish': showGarnish }" :src="require(`@/assets/img/icons/${ingredient.imageName}.${fileType}`)" :alt="ingredient.name" />
  <slot />
</button>
</template>

<script>
export default {
  props: {
    showGarnish: {
      type: Boolean,
      default: false,
    },
    ingredient: {
      type: Object,
      default: () => ({}),
    },
    fileType: {
      type: String,
      default: 'png',
    },
    click: {
      type: Function,
      default: () => {},
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clicked(ingredient) {
      if (this.click) {
        this.click(ingredient)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  @include rem(border-radius, 5px);
}

.ingredient {
  @include rem(margin, 0 0 18px 0);
  @include rem(width, 64px);
  @include rem(height, 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $cream-white;
  outline: 0;
  border: 0;
  cursor: pointer;
  position: relative;
  outline: 0;
  padding: 0;
  
  &.-disabled {
    cursor: default;
  }

  &.-active {
    @include rem(border-width, 3px);
    @include rem(border-radius, 3px);
    border-style: solid;
    border-color: $black;
  }

  > img {
    @include rem(width, 64px);
    @include rem(height, 64px);
    @include rem(padding, 2px);
    max-width: none;
    max-height: none;
  }

  &.-garnish {
    @include rem(width, 96px);
    @include rem(height, 120px);

    > img {
      @include rem(width, 96px);
      @include rem(height, 120px);
      @include rem(max-width, 96px);
    }
  }
}

@media #{$mobile-sm} {
  .ingredient {
    @include rem(margin, 0 0 10px 0);
  }
}

@media #{$wide} {

  .ingredient {
    @include rem(width, 78px);
    @include rem(height, 78px);

    > img {
      @include rem(width, 78px);
      @include rem(height, 78px);
      @include rem(max-width, 78px);
    }

    .garnish & {
      @include rem(width, 100px);
      @include rem(height, 124px);
    }
  }
}


</style>