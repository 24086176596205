<template>
  <transition name="fade" mode="in-out">
    <div class="how-rules-container" v-show="show">
      <button @click="close" class="close"><img src="@/assets/img/close.svg" alt="" /></button>
      <div class="how-rules" ref="rule">
        <div class="content">
          <div class="how">
            <h2>Så tävlar du</h2>
            <p class="-body-2"><strong>1.</strong> Köp 1 valfri produkt från Paulúns.</p>
            <p class="-body-2"><strong>2.</strong> Spara kvittot! Om du vinner ska du kunna visa upp det.</p>
            <p class="-body-2"><strong>3.</strong> Klicka på Starta & mixa din smoothiebowl.</p>
            <p class="-body-2"><strong>4.</strong> Du kan skicka in hur många bidrag som helst men bara vinna en gång.</p>
            <p class="-body-2"><strong>5.</strong> Paulúns Hälsoråd väljer ut de 20 bästa bidragen enligt vår BARA BRA MAT-filosofi. Utifrån kriterier som godast smak, hälsa och naturlighet.</p>
            <a v-if="!showGoodFood" class="good-food-link" href="javascript:;" @click="readMoreAboutGoodFood">Läs mer om BARA BRA MAT</a>
            <div id="bara-bra-mat" class="good-food" v-if="showGoodFood">
              <h3>BARA BRA MAT</h3>
            <p class="-body-2">Vi väljer alltid näringstäta råvaror fullproppade med nyttigheter som fiber, fullkorn, antioxidanter, vitaminer, och mineraler. Vi vet att naturlig och hälsosam mat också kan vara god och njutbar. Vi tillsätter därför aldrig vitt socker, sötningsmedel, honung eller annat som bara är till för att ge sötma. Våra råvaror är utvalda för sitt höga näringsvärde, men också för sin supergoda smak och härliga färg. Vi tror på rena, naturliga råvaror och använder därför aldrig några tillsatser (e-nummer) som exempelvis konserveringsmedel och stabiliseringsmedel.</p>
            </div>
          </div>
          <div class="rules">
            <h3>Tävlingsregler & villkor</h3>
            <p class="-body-2">Tävlingen pågår 4/1–1/4 2021 och är öppen för alla över 18 år som är bosatta i Sverige. Anställda eller anhöriga till anställda på Orkla Foods Sverige AB och Change Reklambyrå AB får inte delta i tävlingen. Vi förbehåller oss rätten att ändra tävlingsperioden vid oförutsedda händelser. Vinstinfo1:a–20:e pris är en Vitamix Ascent 3500i, värde 9495 kr. Premiumblendern i borstat rostfritt stål som kan mixa de allra tuffaste råvarorna till en silkeslen smoothie, soppa eller röra.</p>
            <p class="-body-2">Vinsten kan inte bytas mot kontanter eller andra produkter. Eventuell vinstskatt betalas av vinnaren. De 20 bästa tävlingsbidragen utses till vinnare. Juryn, som består av representanter från Paulúns Hälsoråd, bedömer bidragen utifrån vår BARA BRA MAT-filosofi med kriterier som smak, hälsa och naturlighet. Juryns beslut går ej att överklaga. <a href="https://www.pauluns.se/pauluns-halsorad" target="_blank">Läs mer om Paulúns hälsoråd</a></p>
            <p class="-body-2">Endast en vinst per deltagare är möjlig, oavsett hur många tävlingsbidrag som registrerats. Vinnarna kontaktas via e-post senast 10 dagar efter tävlingsperiodens slut. Vinnarna kan bli ombedda att uppvisa kvitto på en valfri produkt från Paulúns inköpt under tävlingsperioden (4/1 – 1/4 2021).</p>
            <p class="-body-2">Vi förbehåller oss rätten att diskvalificera deltagare vid misstanke om fusk. Efter kampanjperiodens slut kommer vinnarnas namn och tävlingsbidragsnamn att publiceras på pauluns.se samt eventuellt på Instagram och Facebook. Genom att skicka in ditt tävlingsbidrag godkänner du att ditt namn och resultat publiceras vid eventuell vinst. Du godkänner också att Paulúns har rätt att tillaga ditt tävlingsbidrag och använda i marknadsföring. Vi reserverar oss för eventuella tryckfel eller oavsiktliga missförstånd i samband med tävlingen.</p>
            <h4>Hantering av personuppgifter</h4>
            <p class="-body-2">Uppgifterna som du lämnat ovan behandlas av Orkla som är personuppgiftsansvarig. Det betyder att Orkla ansvarar för att dina uppgifter behandlas enligt gällande dataskyddslagstiftning. Vi använder och delar uppgifterna med Change för att utse vinnarna. Inga personuppgifter sparas efter avslutad kampanj, som längst fram till 2021-04-30.</p>
            <h4>Sekretesspolicy</h4>
            <p class="-body-2">Din sekretess är viktig för oss. I denna sekretesspolicy beskrivs vilka personuppgifter som Orkla samlar in från dig, hur vi interagerar med dig och hur vi använder dessa uppgifter.</p>
            <h4>Personuppgifter vi samlar in och hur vi använder dem</h4>
            <p class="-body-2">Change använder insamlade data (namn och e-post) för att vi ska kunna skicka ut vinsten om du vinner. Vi får informationen genom att du fyller i dina uppgifter på den här kampanjsajten.</p>
            <h4>Hur vi delar dina personuppgifter</h4>
            <p class="-body-2">Orkla använder och delar uppgifterna med Change (för att administrera och skicka ut din eventuella vinst.</p>
            <h4>Tillgång till dina personuppgifter</h4>
            <p class="-body-2">Vänligen kontakta privacy@orkla.no om du vill få tillgång till, utlämna, korrigera eller radera personuppgifter vi har lagrat. Du kan också invända, kräva begränsning eller återkalla ditt/dina samtycke(n) när som helst. Du också rikta klagomål till Svenska Datainspektionen (senare Integritetsskyddsmyndigheten) om du vill framföra åsikter gällande vår behandling av personuppgifter.</p>
            <h4>Borttagning av personuppgifter</h4>
            <p class="-body-2">Vi tar bort dina personuppgifter efter att kampanjen är avslutad och eventuellt vinst är utskickad. Vi sparar uppgifterna som längst t o m 2021-04-30.</p>
            <h4>Hur förvarar vi personuppgifterna</h4>
            <p class="-body-2">Tjänster och produkter vi använder för att bearbeta och lagra dina personuppgifter finns inom EU. Det säkerställer att våra leverantörer också följer lagen om GDPR.</p>
            <p class="-body-2 -italic">Detaljerad redogörelse för: Paulúns – Mixa & Vinn!<br />Tillgång: Orkla Foods Sverige AB och Change Reklambyrå AB </p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      show: 'rule/show',
      showGoodFood: 'rule/goodFood',
    }),
  },
  methods: {
    close() {
      document.querySelector('.how-rules').scrollTop = 0
      this.$store.dispatch('rule/showRules', { show: false, goodFood: false })
    },
    readMoreAboutGoodFood() {
      this.$store.dispatch('rule/showRules', { show: true, goodFood: true })
    },
  },
  watch: {
    show(show) {
      if (show) {
        disableBodyScroll(this.$refs.rule)
      } else {
        clearAllBodyScrollLocks()
      }
    },
    showGoodFood(showGoodFood) {
      if (showGoodFood) {
        setTimeout(() => {
          document.querySelector('.how-rules').scrollTo({top: document.getElementById('bara-bra-mat').offsetTop - 70, behavior: 'smooth'})
        }, 400)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.how-rules-container {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  z-index: 1001;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 110px;
    background: rgb(169,173,0);
    background: linear-gradient(to bottom, rgba(169,173,0,1) 80%, rgba(169,173,0,0) 100%);
  }

  .close {
    position: fixed;
    left: 50%;
    top: $gutter;
    transform: translateX(-50%);
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    z-index: 1002;
  }
}

.good-food-link {
  @include rem(margin-top, $gutter);
}

.how-rules {
  @include rem(padding-top, ($gutter * 2));
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $green;
  transform: translateY(0);
  overflow-y: scroll;

  .content {
    @include rem(max-width, 600px);
    @include rem(padding, ($gutter * 2) ($gutter * 2) $gutter * 3);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    p,
    a {
      display: inline-block;
      @include rem(margin-bottom, $gutter / 2);
    }

    .rules {
      p {
        @include rem(margin-bottom, $gutter * 1.5);
      }

      h4 {
        @include rem(margin-bottom, $gutter / 2);
      }
    }

  }

  a {
    color: #000;
  }

  h2,
  h3 {
    @include rem(margin, $gutter * 2 0 $gutter);
    color: $white;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all .2s ease;
}

.fade-enter, .fade-leave-to {
  transform: translateY(100vh);
}
</style>