<template>
  <button class="add-ingredient-button">
    <slot />
  </button>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.add-ingredient-button {
  @include rem(width, 102px);
  @include rem(height, 102px);
  @include rem(border-radius, 51px);
  @include rem(font-size, 12px);
  @include rem(line-height, 16px);
  font-weight: 900;
  text-align: center;
  border: 0;
  text-transform: uppercase;
  background: $black-15;
  color: $white;
  letter-spacing: 1px;
  outline: 0;
  cursor: pointer;

  @media #{$wide} {
    @include rem(width, 100px);
    @include rem(height, 100px);
    @include rem(border-radius, 50px);
  }
}
</style>