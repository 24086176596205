<template>
  <div class="mixer">
    <div class="holder">
      <div class="blender">
        <div class="add-ingredient">
          <router-link to="/tavla/lagg-till-egna-ingredienser">
            <AddIngredientButton>Lägg till<br />en egen ingrediens</AddIngredientButton>
          </router-link>
        </div>
        <div class="floating" @click="showRecipe">
          <Badge :amount="numberOfIngredients" />
          <div class="my-ingredients">
            <span>
              Mina<br />ingredienser
            </span>
          </div>
        </div>
        <sprite-animation
          v-bind:id="'blender'"
          v-bind:spritesheet="spritesheet"
          v-bind:json="spritesheetJson"
          v-bind:autoplay="false"
          v-bind:loop="false"
          v-on:ready="ready"
          ref="animation"
        ></sprite-animation>
      </div>
      <div class="continue">
        <Button :white="true" :click="addGarning">Mixa & gå vidare</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Badge from './Badge'
import Button from './Button'
import { meta, frames } from '@/assets/sprites/spritesheet.json'
import spritesheet from '@/assets/sprites/spritesheet.png'
import AddIngredientButton from './AddIngredientButton'
import SpriteAnimation from './SpriteAnimation'

let spritesheetJson = {
  meta: {
    ...meta,
  },
  frames: [],
}

for(const propt in frames){
  spritesheetJson.frames.push({
    ...frames[propt],
    filename: propt,
  })
}

export default {
  props: {
    startMixing: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      ingredients: 'recipe/ingredients',
      customIngredients: 'recipe/customIngredients',
    }),
    numberOfIngredients() {
      return this.ingredients.length + this.customIngredients.length
    }
  },
  components: {
    Badge,
    Button,
    AddIngredientButton,
    SpriteAnimation,
  },
  data: () => ({
    spritesheetJson,
    spritesheet,
  }),
  mounted() {
  },
  methods: {
    addGarning() {
      if (this.ingredients.length === 0) {
        this.$router.push({ name: 'garnish' })
      } else {
        this.startMixing()
        setTimeout(() => {
          this.$refs.animation.play(117, 180)
          setTimeout(() => {
            this.$router.push({ name: 'garnish' })
          }, 2000)
        }, 400)
      }
    },
    play() {
      if (this.numberOfIngredients === 0) {
        this.$refs.animation.play(0, 1)
      } else if (this.numberOfIngredients === 1) {
        this.$refs.animation.play(1, 39)
      } else if (this.numberOfIngredients === 2) {
        this.$refs.animation.play(39, 81)
      } else if (this.numberOfIngredients > 2) {
        this.$refs.animation.play(81, 117)
      }
    },
    ready() {
      this.play(true)
    },
    showRecipe() {
      this.$store.dispatch('recipe/showRecipe', true)
    },
  },
  watch: {
    numberOfIngredients(numberOfIngredients, previousMumberOfIngredients) {
      if (numberOfIngredients < previousMumberOfIngredients) {
        if (numberOfIngredients === 0) {
          this.$refs.animation.play(0, 1)
        } else if (numberOfIngredients === 1) {
          this.$refs.animation.play(38, 39)
        } else if (numberOfIngredients === 2) {
          this.$refs.animation.play(80, 81)
        } else if (numberOfIngredients > 2) {
          this.$refs.animation.play(116, 117)
        }
      } else {
        this.play()
      }
      
    }
  },
}
</script>

<style lang="scss" scoped>

.mixer {
  @include rem(bottom, $gutter);
  @include rem(padding, $gutter);
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 100;
  overflow-x: hidden;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  > .holder {
    flex-direction: column;
    height: 100%;
  }
}

.blender {
  position: relative;
  transition: all .2s ease;
}

.add-ingredient {
  position: absolute;
  top: 70%;
  left: -20px;
  transform: translateY(-70%);
  z-index: 100;
  transition: all .2s ease;
}

.my-ingredients {
  @include rem(line-height, 16px);
  @include rem(font-size, 12px);
  z-index: 100;
  font-weight: 900;
  text-transform: uppercase;

  span {
    color: $space;
    letter-spacing: 1px;
  }
}

.floating {
  @include rem(width, 120px);
  position: absolute;
  display: flex;
  align-items: center;
  top: 27%;
  right: 0;
  transition: all .2s ease;
  cursor: pointer;

  .badge {
    flex: 0 0 auto;
  }

  > .my-ingredients {
    @include rem(margin-left, $gutter / 3);
  }
}

/deep/ .vue-spritecore {
  display: flex;
  justify-content: center;
}

/deep/ #blender {
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 400px;
}

.continue {
  display: flex;
  width: 100%;
  justify-content: center;
  transition: all .2s ease;
}

@media #{$mobile-xs} {
  .mixer {
    padding: 0;
  }

  /deep/ #blender {
    max-width: 170px;
    max-height: 170px;
  }

  .add-ingredient {
    left: 0px;
  }
}

@media #{$desktop-sm} {
  .floating {
    right: -$gutter !important;
  }

  /deep/ #blender {
    max-width: 200px;
    max-height: 200px;
  }
}

@media #{$mobile-sm} {
  /deep/ #blender {
    max-width: 170px;
    max-height: 170px;
  }
}

@media #{$wide} {
  .floating {
    @include rem(width, 150px);
    right: $gutter;
  }
}
</style>