import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    name: 'start',
    path: '/',
    component: () => import('@/pages/Start.vue'),
  },
  {
    name: 'ingredients',
    path: '/tavla/valj-ingredienser',
    component: () => import('@/pages/Ingredients.vue'),
    children: [
      {
        name: 'info',
        path: '/tavla/info',
        component: () => import('@/pages/Info.vue'),
      },
      {
        name: 'addCustomIngredient',
        path: '/tavla/lagg-till-egna-ingredienser',
        component: () => import('@/pages/AddCustomIngredient.vue'),
      }
    ]
  },
  {
    name: 'garnish',
    path: '/tavla/valj-topping',
    component: () => import('@/pages/Garnish.vue'),
    children: [
      {
        name: 'noIngredientsAdded',
        path: '/tavla/inga-ingredienser-valda',
        component: () => import('@/pages/NoIngredientsAdded.vue'),
      }
    ]
  },
  {
    name: 'finish',
    path: '/tavla/avsluta',
    component: () => import('@/pages/Finish.vue'),
    children: [
      {
        name: 'noIngredientsAdded',
        path: '/tavla/inga-ingredienser-valda',
        component: () => import('@/pages/NoIngredientsAdded.vue'),
      }
    ]
  },
  {
    name: 'typography',
    path: '/typography',
    component: () => import('@/pages/Typography.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: '-active',
  routes,
})

export default router