<template>
  <span :class="{ '-animate': animateBadge }" class="badge">
   {{amount}}
  </span>
</template>

<script>
export default {
  props: {
    amount: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    animateBadge: false,
  }),
  watch: {
    amount(currentAmount, previousAmount) {
      if (currentAmount != previousAmount && !this.animateBadge) {
        // animate
        this.animateBadge = true

        setTimeout(() => {
          this.animateBadge = false
        }, 100)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.badge {
  @include rem(font-size, 16px);
  @include rem(line-height, 19px);
  @include rem(width, 28px);
  @include rem(height, 28px);
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  font-family: $font-family-body;
  color: $white;
  letter-spacing: 0;
  border-radius: 26px;
  transition: transform .1s ease-in;
  padding: 1px 0 0 1px;
}

.-animate {
  transform: scale(1.1);
}

@media #{$wide} {
  .badge {
    @include rem(font-size, 24px);
    @include rem(line-height, 32px);
    @include rem(width, 42px);
    @include rem(height, 42px);
  }
}
</style>