<template>
  <button :class="classNames" @click="click">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    click: {
      type: Function,
      default: () => {},
    },
    white: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classNames() {
      return ['btn', this.white ? '-white' : '-primary', this.disabled ? '-disabled' : '']
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  @include rem(padding, 12px 20px);
  @include rem(font-size, 16px);
  @include rem(line-height, 24px);
  @include rem(border-radius, 2px);
  min-width: 280px;
  text-decoration: none;
  outline: 0;
  display: inline-block;
  justify-content: center;
  text-align: center;
  font-weight: 350;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 0;
  cursor: pointer;
}


.-primary {
  background-color: $green;
  color: $white;

  &:hover {
    background-color: $green-hover;
  }

  &.-disabled {
    background-color: $green-75;
    cursor: default;

    &:hover {
      background-color: $green-75;
    }
  }
}

.-white {
  background-color: $merino;
  color: #000;

  &:disabled {
    background-color: rgba($merino, .9);
  }
}
</style>