<template>
  <transition name="fade" mode="in-out">
    <div class="my-recipe" v-show="show" ref="myRecipe">
      <div class="scroll-container">
        <div class="ingredients">
          <div class="holder">
            <button @click="close" class="close"><img src="@/assets/img/close.svg" alt="" /></button>
            <h2 class="-text-white">{{recipeName}}</h2>
              <h3>Valda ingredienser</h3>
              <p class="-body-1 -message" v-if="ingredients.length === 0">Du har inte valt några ingredienser.</p>
              <ul class="ingredient-list" v-if="ingredients.length > 0">
                <li v-for="(ingredient, index) in ingredients" :key="index">
                  <IngredientButton :ingredient="ingredient" :is-disabled="true">
                    <button class="remove"><img src="@/assets/img/remove.svg" alt="" @click="removeIngredient(ingredient)" /></button>
                  </IngredientButton>
                </li>
              </ul>
              <div class="custom-ingredients" v-if="customIngredients.length > 0">
                <p class="-caption">Egna ingredienser</p>
                <div>
                  <strong v-if="customIngredients.length > 0">{{ customIngredients.join(', ') }}</strong>
                  <p class="-caption"><span @click="changeCustomIngredients">Ändra</span></p>
                </div>
              </div>
              <Button :white="true" :click="goToIngredients">{{ ingredients.length > 0 ? 'Välj fler ingredienser' : 'Välj ingredienser' }}</Button>
          </div>
        </div>
        <div class="garnish">
          <div class="holder">
            <h3>Vald topping</h3>
            <p class="-body-1 -message" v-if="garnish.length === 0">Du har inte valt någon topping.</p>
            <ul class="garnish-list" v-if="garnish.length > 0">
              <li v-for="(ingredient, index) in garnish" :key="index">
                <IngredientButton :ingredient="ingredient" :is-disabled="true" :show-garnish="true">
                  <button class="remove"><img src="@/assets/img/remove.svg" alt="" @click="removeGarnish()" /></button>
                </IngredientButton>
              </li>
            </ul>
            <Button :click="goToGarnish">{{ garnish.length > 0 ? 'Välj annan topping' : 'Välj topping' }}</Button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { mapGetters } from 'vuex'
import IngredientButton from './IngredientButton'
import Button from './Button'

export default {
  computed: {
    ...mapGetters({
      show: 'recipe/showRecipe',
      ingredients: 'recipe/ingredients',
      garnish: 'recipe/garnish',
      customIngredients: 'recipe/customIngredients',
      name: 'recipe/name',
    }),
    recipeName() {
      return this.name.length > 0 ? this.name : 'Mitt recept'
    },
  },
  watch: {
    show(show) {
      if (show) {
        disableBodyScroll(this.$refs.myRecipe)
      } else {
        clearAllBodyScrollLocks()
      }
    },
  },
  components: {
    IngredientButton,
    Button,
  },
  methods: {
    close() {
      this.$store.dispatch('recipe/showRecipe', false)
    },
    changeCustomIngredients() {
      this.close()
      this.$router.push({ name: 'addCustomIngredient' })
    },
    goToIngredients() {
      this.close()
      this.$router.push({ name: 'ingredients' })
    },
    goToGarnish() {
      this.close()
      this.$router.push({ name: 'garnish' })
    },
    removeIngredient(ingredient) {
      this.$store.dispatch('recipe/removeIngredient', ingredient.id)
    },
    removeGarnish() {
      this.$store.dispatch('recipe/removeGarnish')
    }
  },
}
</script>

<style lang="scss" scoped>
.my-recipe {
  width: 100vw;
  min-height: 100vh;
//  height: 100%;
  position: fixed;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  top: 0;
  left: 0;
  background-color: $green;
  transform: translateY(0);
  z-index: 500;
}
.scroll-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
//  overflow: auto;
//  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
}

.ingredients {
  width: 100%;
  text-align: center;

  h2,
  h3 {
    @include rem(margin-bottom, $gutter / 2);
  }

  .btn {
    @include rem(margin, 32px 0);
  }
}

.garnish {
  @include rem(padding, $gutter 0);
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $cream-white;
  flex: 1;
  min-height: 340px;

  .holder {
    background-color: $cream-white;
  }

  h2,
  h3 {
    @include rem(margin-bottom, 23px);
  }
}

.-message {
  @include rem(margin-bottom, $gutter);
}

/deep/ .remove {
  background: transparent;
  border: 0;
  position: absolute;
  padding: 0;
  cursor: pointer;
  width: 26px;
  height: 26px;
  outline: 0;
}

.ingredient-list {
  @include rem(grid-column-gap, $gutter);
  @include rem(margin-top, $gutter);
  grid-template-columns: repeat(auto-fit, 64px);
  flex-wrap: wrap;
  display: grid;
  justify-content: center;
  width: 100%;

  /deep/ .remove {
    right: -13px;
    top: -13px;
  }
}

.garnish-list {
  @include rem(grid-column-gap, $gutter);
  @include rem(margin-top, 12px);
  display: flex;
  justify-content: center;
  width: 100%;

  /deep/ .remove {
    right: -13px;
    top: 0;
  }
}

.close {
  @include rem(margin, $gutter / 2 auto $gutter);
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.holder {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-ingredients {
  text-align: left;
  align-self: flex-start;

  > .-caption {
    @include rem(margin, $gutter / 2 0 $gutter / 2);
  }

  > div {
    display: flex;
    width: 100%;

    strong {
      @include rem(margin-right, $gutter);
      flex: 0 1 80%;
      display: flex;
      flex-wrap: wrap;
    }

    .-caption {
      flex: 0;
      margin-left: auto;
      
      > span {
        cursor: pointer;
      }
    }
  }
}

@media #{$wide} {

  .garnish {
    @include rem(padding, $gutter * 2 0);

    .garnish-list {
      .ingredient {
        @include rem(width, 106px);
        @include rem(height, 124px);

        > img {
          @include rem(width, 106px);
          @include rem(height, 124px);
        }
      }
    }
  }

  .close {
    @include rem(margin, $gutter * 1.5 auto $gutter * 3);
  }

  .ingredient-list {
    grid-template-columns: repeat(auto-fill, 78px);
  }

  .ingredients {
    h2,
    h3 {
      @include rem(margin-bottom, $gutter * 1.5);
    }

    h3 {
      @include rem(margin-bottom, $gutter);
    }
  }

  /deep/ .remove {
    width: 32px;
    height: 32px;
    right: -18px;
    top: -18px;
  }

  .custom-ingredients {
    width: 100%;

    > .-caption {
      @include rem(margin, $gutter 0);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all .2s ease;
}

.fade-enter, .fade-leave-to {
  transform: translateY(100vh);
}
</style>