<template>
  <div :class="{ '-garnish': showGarnish }" class="ingredients">
    <div class="holder">
      <IngredientButton v-for="(ingredient, index) in filteredIngredientsByCategory" :key="index" :is-active="filteredIngredients.findIndex(i => i.id === ingredient.id) > -1" :ingredient="ingredient" :show-garnish="showGarnish" :click="addIngredient" />
    </div>
    <div class="bottom">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IngredientButton from './IngredientButton'

export default {
  props: {
    showGarnish: {
      type: Boolean,
      default: false,
    },
    fileType: {
      type: String,
      default: 'svg',
    },
  },
  mounted() {
    const list = this.showGarnish ? this.garnishCategories : this.categories
    if (list && list.length > 0) {
      this.$store.dispatch('recipe/currentCategoryId', list[0].id)
    }
  },
  components: {
    IngredientButton,
  },
  computed: {
    ...mapGetters({
      categories: 'recipe/categories',
      garnishCategories: 'recipe/garnishCategories',
      ingredientsByCategory: 'recipe/ingredientsByCurrentCategory',
      garnishByCategory: 'recipe/garnishByCurrentCategory',
      garnish: 'recipe/garnish',
      ingredients: 'recipe/ingredients',
    }),
    filteredIngredients() {
      if (this.showGarnish) {
        return this.garnish
      }
      return this.ingredients
    },
    filteredIngredientsByCategory() {
      if (this.showGarnish) {
        return this.garnishByCategory
      }
      return this.ingredientsByCategory
    }
  },
  methods: {
    addIngredient({ ingredient, isActive }) {
      const { id, name, imageName } = ingredient
      const payload = { 
        id,
        name,
        imageName,
      }
      if (isActive) {
        this.$store.dispatch(this.showGarnish ? 'recipe/removeGarnish' : 'recipe/removeIngredient', id)
      } else {
        this.$store.dispatch(this.showGarnish ? 'recipe/addGarnish' : 'recipe/addIngredient', payload)
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>

.ingredients {
  @include rem(margin-top, $gutter);
  transition: all .2s ease;
  z-index: 150;
  position: relative;
}

.holder {
  grid-template-columns: repeat(auto-fit, 64px);
  grid-column-gap: 20px;
  display: grid;
  justify-content: center;

  .-garnish & {
    grid-template-columns: repeat(auto-fill, 96px);
  }
}

@media #{$mobile-xs} {
  .ingredients {
    .holder {
      @include rem(padding, 0 $gutter / 2);
      grid-column-gap: 10px;
    }
  }
}

@media #{$mobile-sm} {
  .ingredients {
    @include rem(margin-top, $gutter / 2);

    .holder {
      @include rem(grid-column-gap, $gutter / 2);
    }
  }
}

@media #{$wide} {
  .holder {
    grid-template-columns: repeat(auto-fill, 78px);

    .-garnish & {
      grid-template-columns: 100px 100px 100px;
    }
  }
}
</style>