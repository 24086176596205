<template>
  <div :class="{ '-garnish': showGarnish }" class="categories">
    <div class="holder">
      <button v-for="(category, index) in filteredCategories" :class="{ '-active': (currentCategoryId === category.id) }" :key="index" @click="setCurrentCategory(category.id)">{{ category.name }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    showGarnish: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      categories: 'recipe/categories',
      garnishCategories: 'recipe/garnishCategories',
      currentCategoryId: 'recipe/currentCategoryId',
    }),
    filteredCategories() {
      if (this.showGarnish) {
        return this.garnishCategories
      }
      return this.categories
    }
  },
  methods: {
    setCurrentCategory(categoryId) {
      this.$store.dispatch('recipe/currentCategoryId', categoryId)
    },
    checkCurrentCategory() {
      if (!this.currentCategoryId && this.filteredCategories.length > 0) {
        this.$store.dispatch('recipe/currentCategoryId', this.filteredCategories[0].id)
      }
    }
  },
  mounted() {
    this.checkCurrentCategory()
  },
  watch: {
    filteredCategories(filteredCategories) {
      if (filteredCategories.length > 0) {
        this.checkCurrentCategory()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.categories {
  background-color: $green-darker;
  display: flex;
  justify-content: center;
  transition: all .2s ease;

  &.-garnish {
    > .holder {
      justify-content: center;

        > * {
          @include rem(padding-right, 58px);

          &:last-child {
            padding-right: 0;
          }
        }
    }
  }
}

.holder {
  justify-content: space-between;
}

button {
  @include rem(padding, $gutter 0);
  @include rem(font-size, 14px);
  @include rem(line-height, 22px);
  background-color: transparent;
  border: 0;
  font-weight: 300;
  color: $white;
  letter-spacing: 1px;
  text-transform: uppercase;
  outline: 0;
  cursor: pointer;

  &.-active {
    text-decoration: underline;
  }
}

@media #{$mobile-xs} {
  .holder {
    @include rem(padding, 0 $gutter / 2 !important);

    button {
      @include rem(padding, $gutter / 2 0);
    }
  }
}

@media #{$mobile-sm} {
  .holder {
    @include rem(padding, 0 $gutter);

    button {
      @include rem(padding, $gutter / 1.5 0);
    }
  }
}

@media #{$wide} {
  button {
    @include rem(font-size, 18px);
    @include rem(line-height, 26px);
  }
}

</style>