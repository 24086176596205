import axios from 'axios'

const { VUE_APP_SERVER_URL } = process.env
const baseAxios = axios.create({
  baseURL: `${VUE_APP_SERVER_URL}/api/`,
})

const endpoints = () => ({
  getCategories: () => baseAxios.get('categories'),
  sendRecipe: (recipe) => baseAxios.post('recipe/add', recipe),
})

export default endpoints()
