import Vue from 'vue'
import Vuex from 'vuex'
import recipe from './recipe'
import rule from './rule'
import mobilenav from './mobilenav'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    recipe,
    rule,
    mobilenav,
  },
})