import { api } from '@/helpers'

const STORAGE_KEY = 'pauluns_instructions_displayed'

const actions = {
  showRecipe({ commit }, show) {
    commit('showRecipe', show)
  },
  async loadAvailableCategories({ commit }) {
    try {
      const { data } = await api.getCategories()
      commit('setCategories', data)
    } catch (err) {
      console.log(err.message)
    }
  },
  currentCategoryId({ commit }, categoryId) {
    commit('setCurrentCategoryId', categoryId)
  },
  resetRecipe({ commit }) {
    commit('resetRecipe')
  },
  addCustomIngredient({ commit, state }, customIngredientName) {
    if (customIngredientName && !state.customIngredients.includes(customIngredientName)) {
      commit('addCustomIngredient', customIngredientName)
    }
  },
  setRecipeName({ commit }, name) {
    commit('setRecipeName', name)
  },
  setRecipeEmail({ commit }, email) {
    commit('setRecipeEmail', email)
  },
  removeCustomIngredient({ commit }, index) {
    commit('removeCustomIngredient', index)
  },
  setInstructionsDisplayed({ commit }) {
    commit('setInstructionsDisplayed')
  },
  addIngredient({ commit }, ingredient) {
    commit('addIngredient', ingredient)
  },
  removeIngredient({ commit }, ingredientId) {
    commit('removeIngredient', ingredientId)
  },
  addGarnish({ commit }, garnish) {
    commit('addGarnish', garnish)
  },
  removeGarnish({ commit }) {
    commit('removeGarnish')
  },
  addCustomIngredients({ commit }, customIngredients) {
    commit('addCustomIngredients', customIngredients)
  },
  async sendRecipe({ commit, state }, { acceptNewsletter, personalization, regads }) {
    if (state) {
      const { email, name, ingredients, customIngredients, garnish } = state
      let ingredientIds = ingredients.concat(garnish).map(ingredient => ingredient.id)
      try {
        await api.sendRecipe({
          email,
          name,
          newsletter: acceptNewsletter ? 1 : 0,
          personalization: personalization ? 1 : 0,
          regads: regads ? 1 : 0,
          ingredientIds,
          customIngredients,
        })
        commit('recipeSent')
      } catch ({ response }) {
        commit('recipeSentError')
      }
    }
  },
}

const state = {
  name: '',
  email: '',
  ingredients: [],
  garnish: [],
  customIngredients: [],
  recipeSent: 0,
  recipeSentError: 0,
  categories: [],
  currentCategoryId: null,
  showRecipe: false,
}

const getters = {
  hasDisplayedInstructions: () => localStorage.getItem(STORAGE_KEY) != null,
  name: (state) => state.name,
  email: (state) => state.email,
  showRecipe: (state) => state.showRecipe,
  categories: (state) => state.categories.filter(c => !c.isGarnish && c.id != 9),
  garnishCategories: (state) => state.categories.filter(c => c.isGarnish && c.id != 9),
  ingredients: (state) => state.ingredients,
  garnish: (state) => state.garnish,
  customIngredients: (state) => state.customIngredients,
  recipeSentError: (state) => state.recipeSentError,
  recipeSent: (state) => state.recipeSent,
  currentCategoryId: (state) => state.currentCategoryId,
  ingredientsByCurrentCategory: (state) => {
    if (state.categories.length === 0 || !state.currentCategoryId) {
      return []
    }

    const category = state.categories.find(c => c.id === state.currentCategoryId)
    if (category) {
      return category.ingredients
    }

    return []
  },
  garnishByCurrentCategory: (state) => {
    if (state.categories.length === 0) {
      return []
    }

    const category = state.categories.find(c => c.id === state.currentCategoryId)
    if (category) {
      return category.ingredients
    }

    return []
  }
}

const mutations = {
  setRecipe(state, recipe) {
    state.recipe = recipe
  },
  showRecipe(state, show) {
    state.showRecipe = show
  },
  resetRecipe(state) {
    state.name = null
    state.email = null,
    state.ingredients = []
    state.garnish = []
    state.customIngredients = []
  },
  recipeSent(state) {
    state.recipeSent += 1
  },
  recipeSentError(state) {
    state.recipeSentError += 1
  },
  setCategories(state, categories) {
    state.categories = categories
  },
  setRecipeEmail(state, email) {
    state.email = email
  },
  setRecipeName(state, name) {
    state.name = name
  },
  setInstructionsDisplayed() {
    localStorage.setItem(STORAGE_KEY, true)
  },
  // ingredients
  addIngredient(state, ingredient) {
    state.ingredients.push(ingredient)
  },
  removeIngredient(state, ingredientId) {
    const ingredientIndex = state.ingredients.findIndex(i => i.id === ingredientId)
    if (ingredientIndex > -1) {
      state.ingredients.splice(ingredientIndex, 1)
    }
  },
  // garnish
  addGarnish(state, garnish) {
    state.garnish = [garnish]
  },
  removeGarnish(state) {
    state.garnish = []
  },
  // custom ingredients
  addCustomIngredients(state, customIngredients) {
    state.customIngredients = state.customIngredients.concat(customIngredients)
  },
  removeCustomIngredient(state, index) {
    state.customIngredients.splice(index, 1)
  },
  updateCustomIngredientsFromRecipe(state, ingredients) {
    state.customIngredients = ingredients
  },
  saveToLocalStorage(state) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state.recipe))
  },
  setCurrentCategoryId(state, categoryId) {
    state.currentCategoryId = categoryId
  },
}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
}
