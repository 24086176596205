<template>
  <div class="blender-splash">
    <img src="@/assets/img/blender-splash-2.png" alt="" />
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.blender-splash {
  @include rem(height, 221px);
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: flex-end;

  img {
    position: relative;
    top: -10px;
    width: auto;
    max-height: 216px;
    height: 100%;
    object-fit: contain;
  }

  @media #{$tall} {
    @include rem(height, 392px);

    img {
      max-height: 389px;
    }
  }
}

@media #{$mobile-xs} {
  .blender-splash {
    @include rem(height, 180px);
    margin-top: -20px;
  }
}

@media #{$wide} {
  .blender-splash {
    img {
      top: -20px;
    }
  }
}

@media #{$desktop-sm} {
  .blender-splash {
    img {
      top: -10px;
    }
  }
}
</style>

