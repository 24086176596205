<template>
  <div id="app" :class="'page-' + currentRouteName">
    <Header />
    <MobileMenuButton />
    <transition name="slide" mode="out-in">
      <router-view />
    </transition>
    <Rules />
    <Recipe />
    <InfoButton />
  </div>
</template>

<script>
import { Rules, Recipe, Header, MobileMenuButton, InfoButton } from '@/components'

export default {
  components: {
    Rules,
    Recipe,
    Header,
    MobileMenuButton,
    InfoButton,
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },  
  mounted() {
    this.$store.dispatch('recipe/loadAvailableCategories')
  },
}
</script>

<style lang="scss">
@import '@/styles/base.scss';

#app {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  &.page-start,
  &.page-garnish,
  &.page-ingredients,
  &.page-addCustomIngredient,
  &.page-noIngredientsAdded,
  &.page-info {
    height: 100%;
  }

  /* Bug fix for iOS which apparently still can't handle flexbox */
  @supports (-webkit-touch-callout: none) {
    &.page-garnish {
      background: $cream-white;
    }
  }

  /deep/ .info-btn {
    #oval {
      fill: $white;
    }
    #i {
      fill: $green;
    }
  }

  @media #{$menu-wide} {

    /deep/ .info-btn {
      #oval {
        fill: $white;
      }
      #i {
        fill: $green;
      }
    }

    &.page-garnish,
    &.page-finish,
    &.page-start {
      /deep/ .info-btn {
        #oval {
          fill: $green;
        }
        #i {
          fill: $white;
        }
      }
    }

    .info-btn {
      position: fixed;
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition-duration: .2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.slide-enter,
.slide-leave-active {
  opacity: 0;
}

</style>
