<template>
  <header ref="header" :class="{ '-opened': show }">
    <div class="brand">
      <a href="https://www.pauluns.se/">
        <img src="@/assets/img/pauluns-logo.svg" alt="pauluns" />
      </a>
    </div>
    <nav>
      <ul>
        <li><a href="https://www.pauluns.se/produkter/">Produkter</a></li>
        <li><a href="https://www.pauluns.se/recipes/">Recept</a></li>
        <li><a href="https://www.pauluns.se/bara-bra-mat/">Bara bra mat</a></li>
        <li><a href="https://www.pauluns.se/pauluns-halsorad/">Paulúns hälsoråd</a></li>
        <li><a href="https://www.pauluns.se/om-pauluns/">Om Paulúns</a></li>
        <li><a href="https://www.pauluns.se/kontakt/">Kontakt</a></li>
      </ul>
    </nav>
    <div class="social">
      <a href="https://www.facebook.com/pauluns"><img src="@/assets/img/facebook.svg" alt="Följ oss på Facebook" /></a>
      <a href="https://www.instagram.com/paulunssverige/"><img src="@/assets/img/instagram.svg" alt="Följ oss på instagram" /></a>
    </div>
  </header>
</template>

<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      show: 'mobilenav/show',
    }),
  },
  watch: {
    show(show) {
      if (show) {
        disableBodyScroll(this.$refs.header)
      } else {
        clearAllBodyScrollLocks()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  @include rem(padding, $gutter);
  @include rem(max-width, 1200px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  background-color: $green;
  overflow-y: scroll;
  z-index: 200;
  flex: 0 0 66px;

  &.-opened {
    position: fixed;
    min-height: 100vh;
  }
}

nav {
  @include rem(margin-bottom, $gutter * 2);
  display: none;

  .-opened & {
    display: flex;
  }

  ul {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;

    a {
      @include rem(font-size, 18px);
      @include rem(padding, $gutter / 2 0);
      display: inline-block;
      text-transform: uppercase;
      font-weight: 300;
      color: $white;
      text-decoration: none;
      text-align: center;
    }
  }
}

.brand {
  .-opened & {
    @include rem(margin-bottom, $gutter * 3);
  }
}


img {
  @include rem(width, 105px);
  @include rem(height, 35px);
}

.social {
  display: flex;
  height: 100%;
  align-items: center;
  display: none;

  .-opened & {
    display: flex;
  }

  a {
    @include rem(width, 40px);
    @include rem(height, 40px);
    @include rem(border-radius, 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;

    & + a {
      @include rem(margin-left, $gutter / 2);
    }
  }

  img {
    @include rem(width, 24px);
    @include rem(height, 24px);
    fill: $green;
  }
}

@media #{$menu-wide} {
  header {
    @include rem(height, 100px);
    @include rem(padding, 0 $gutter);
    display: flex;
    position: relative;
    min-height: auto;
    flex-direction: row;
    position: relative;
    min-height: auto;
    z-index: 0;
    flex: 0 0 100px;
    overflow: auto;
  }

  .brand {
    @include rem(margin-right, $gutter);
    display: flex;
    height: 100%;
    align-items: center;
    margin-bottom: 0;
  }

  nav {
    display: flex;
    justify-content: center;
    flex: 1;
    margin-bottom: 0;

    ul {
      @include rem(padding, 16px 0);
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
      text-align: left;

      li {
        flex: 1;
      }
      
      a {
        @include rem(padding, $gutter / 2 $gutter / 2);
        color: $white;
        display: block;
      }
    }
  }

  .social {
    @include rem(margin-left, $gutter);
    display: flex;
  }

  img {
    @include rem(width, 160px);
    @include rem(height, 68px);
  }
}
</style>
