const actions = {
  async showMobileNav({ commit }, show) {
    commit('showMobileNav', show)
  },
}

const state = {
  show: false,
}

const getters = {
  show: (state) => state.show,
}

const mutations = {
  showMobileNav(state, show) {
    state.show = show
  },
}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
}
