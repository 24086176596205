<template>
  <button :class="{ 'is-active': show }" @click="toggle" class="hamburger hamburger--collapse" type="button">
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
  </button>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      show: 'mobilenav/show',
    })
  },
  methods: {
    toggle() {
      this.$store.dispatch('mobilenav/showMobileNav', !this.show)
    },
  },
}
</script>

<style lang="scss" scoped>
.hamburger {
  z-index: 250;
  position: absolute;
  outline: 0;
  top: 0;
  right: 0;
}
@media #{$menu-wide} {
  .hamburger {
    display: none;
  }
}
</style>
