const actions = {
  async showRules({ commit, state }, { show, goodFood }) {
    commit('showRules', show)
    if (goodFood) {
      commit('setGoodFood', goodFood)
    } else if (!goodFood && state.goodFood) {
      commit('setGoodFood', false)
    }
  },
}

const state = {
  show: false,
  goodFood: false,
}

const getters = {
  show: (state) => state.show,
  goodFood: (state) => state.goodFood,
}

const mutations = {
  showRules(state, show) {
    state.show = show
  },
  setGoodFood(state, goodFood) {
    state.goodFood = goodFood
  }
}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
}
